import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import AxiosInstance from "../../../services/axiosInstance"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"

const PeopleAndPermissionPanel = ({ eventContactData, selectedId, getEvent }) => {
  const [settingRole, setSettingRole] = useState([]),
    [userSearchResult, setUserSearchResult] = useState([]),
    [existingUserDefaultName, setExistingUserDefaultName] = useState(""),
    [existingUserDefaultId, setExistingUserDefaultId] = useState(""),
    [existingUserDefaultImage, setExistingUserDefaultImage] = useState(""),
    [contactId, setContactId] = useState(""),
    [showContactButton, setShowContactButton] = useState(true),
    [showContactButtonGroup, setShowContactButtonGroup] = useState(true),
    [showContact, setShowContact] = useState(false),
    [showContactGroup, setShowContactGroup] = useState(false),
    [selectedRole, setSelectedRole] = useState("")

  const uploadedFilePath = "StaticImages/avatar.gif"

  const contactSearchData = e => {
    let searchUser = e.target.value
    if (searchUser.length < 2) {
      setUserSearchResult([])
      setContactId("")
      return false
    }

    AxiosInstance.get(`/contact/`, {
      params: {
        page: 1,
        search: searchUser ?? "",
      },
    }).then(response => {
      const displayedItems = response.data.items.slice(0, 10)
      setUserSearchResult(displayedItems)
    })
  }

  const businessRoleTable = () => {
    AxiosInstance.get("/settings/role")
      .then(response => {
        if (response.data.items.length === 0) {
          setSettingRole(null)
        } else {
          setSettingRole(response.data.items)
          setSelectedRole(response.data.items[0]?.id)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const removeEventContact = eventContactId => {
    return new Promise(async () => {
      await AxiosInstance.delete(`calendar/un-assign-calendar-contact/${eventContactId}`)
        .then(function (response) {
          if (response.status === 200) {
            getEvent(selectedId)
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  const saveCalendarContact = () => {
    return new Promise(async () => {
      if (!contactId) {
        alert("Please select Contact.")
        return false
      }

      await AxiosInstance.post(`calendar/assign-calendar-contact/${selectedId}/${contactId}/${selectedRole}`)
        .then(function (response) {
          if (response.status === 200) {
            getEvent(selectedId)
            setExistingUserDefaultName("")
            setSelectedRole("")
            setUserSearchResult([])
            setContactId("")
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  return (
    <Accordion defaultActiveKey="0" id="peoplePanelAccordion">
      <Accordion.Item eventKey="0" className="panel panel-green">
        <Accordion.Header>
          <span className="panel-heading-icon">
            <i className="bi bi-people-fill" />
          </span>
          <span className="panel-heading-title">People & Permissions</span>
        </Accordion.Header>
        <Accordion.Body id="people_container">
          <div className="row showUserDetailsContainer">
            {eventContactData.map((eventContact, contactIndex) => {
              const contactData = eventContact.contact
              return (
                <div className="col-lg-6" key={contactIndex}>
                  <div className="teamMember">
                    <OverlayTrigger overlay={<Tooltip>Remove user?</Tooltip>}>
                      <Link
                        to={"#"}
                        className="btnRemoveEventUser"
                        onClick={e => {
                          e.preventDefault()
                          removeEventContact(eventContact.id)
                        }}
                      >
                        <i className="bi bi-x-lg" />
                      </Link>
                    </OverlayTrigger>

                    <div className="userAvatar">
                      <img
                        src={`${process.env.GATSBY_WRANGLER_URL}${
                          contactData.profilePic ? contactData.profilePic : uploadedFilePath
                        }`}
                        width={40}
                        height={40}
                        alt={contactData.name}
                      />
                    </div>
                    <div className="userInfo">
                      <Link to={`/contacts/detail/${contactData.id}`}>
                        <strong>{contactData.name}</strong>
                      </Link>

                      <strong className="ms-1">
                        {eventContact.settingRole && `(${eventContact.settingRole?.role})`}
                      </strong>

                      <div className="contactListQuickTools ms-2">
                        {contactData.emails.length > 0 && contactData.emails[0].email && (
                          <OverlayTrigger overlay={<Tooltip>{contactData.emails[0].email ?? ""}</Tooltip>}>
                            <Link
                              to={`/contacts/detail/${contactData.id}?email=${contactData.emails[0].email}`}
                              className="btnOpenChatDockEmail"
                            >
                              <i className="bi bi-envelope-fill" />
                            </Link>
                          </OverlayTrigger>
                        )}

                        {contactData.phones.length > 0 && contactData.phones[0].phone && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip>
                                {contactData.phones[0].phone ? `SMS to ${contactData.phones[0].phone}` : ""}
                              </Tooltip>
                            }
                          >
                            <Link to={"#"} className="btnOpenChatDockSMS">
                              <i className="bi bi-phone-fill" />
                            </Link>
                          </OverlayTrigger>
                        )}

                        {contactData.phones.length > 0 && contactData.phones[0].phone && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip>
                                {contactData.phones[0].phone ? `Call to ${contactData.phones[0].phone}` : ""}
                              </Tooltip>
                            }
                          >
                            <Link to={"#"} className="btnOpenChatDockPhoneCampaignDirectCall">
                              <i className="bi bi-telephone-fill" />
                            </Link>
                          </OverlayTrigger>
                        )}

                        {contactData.addresses.length > 0 && contactData.addresses[0].address && (
                          <OverlayTrigger
                            overlay={<Tooltip>{contactData.addresses[0].address ?? ""}</Tooltip>}
                          >
                            <Link to={"#"} className="btnOpenChatDockPostalMail">
                              <i className="bi bi-geo-alt-fill" />
                            </Link>
                          </OverlayTrigger>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          {showContact && (
            <div className="row g-0 invoiceAddTeamUser mt-3 socialSearchModal">
              <div className="col-md-9 mx-auto p-0 p-md-3 BC_Multiple_User">
                <div className="colHeading position-relative">
                  <strong>
                    <i className="bi bi-person-plus-fill" /> People &amp; Roles
                  </strong>
                </div>
                <div className="dh-block-body" style={{ padding: "15px" }}>
                  <div className="d-block addNewSocialUserRow">
                    <div className="row BC_Multiple_User">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-5 mb-1 mb-lg-0">
                            <div className="bc-wrapper">
                              <input
                                type="hidden"
                                id="socialAutoSearchId"
                                name="socialAutoSearchId"
                                defaultValue={existingUserDefaultId}
                              />

                              <input
                                type="text"
                                className={`form-control ${contactId ? "userSelectedInput" : ""} `}
                                name="socialAutoSearch"
                                placeholder="Name, Phone or Email"
                                autoComplete="off"
                                onKeyUp={contactSearchData}
                                data-query={existingUserDefaultName}
                                value={existingUserDefaultName}
                                onChange={e => setExistingUserDefaultName(e.target.value)}
                              />

                              {userSearchResult.length > 0 && (
                                <div className="bc-menu list-group" style={{ display: "block" }}>
                                  {userSearchResult.map((searchUserData, searchUserIndex) => {
                                    return (
                                      <a
                                        // href="#"
                                        className="list-group-item"
                                        data-id={searchUserData.id}
                                        data-label={searchUserData.name}
                                        key={searchUserIndex}
                                        onClick={() => {
                                          const defaultImageUrl = `${process.env.GATSBY_WRANGLER_URL}/${
                                            searchUserData.profilePic
                                              ? searchUserData.profilePic
                                              : uploadedFilePath
                                          }`
                                          setContactId(searchUserData.id)
                                          setExistingUserDefaultName(searchUserData.name)
                                          setExistingUserDefaultImage(defaultImageUrl)
                                          setUserSearchResult([])
                                          setExistingUserDefaultId(searchUserData.id)
                                        }}
                                      >
                                        <img
                                          className="bc-user-avatar"
                                          src={`${process.env.GATSBY_WRANGLER_URL}/${
                                            searchUserData.profilePic
                                              ? searchUserData.profilePic
                                              : uploadedFilePath
                                          }`}
                                          alt={searchUserData.name}
                                        />
                                        {searchUserData.name}
                                      </a>
                                    )
                                  })}
                                </div>
                              )}

                              {contactId && (
                                <img
                                  className="selectedUserAvatar"
                                  src={existingUserDefaultImage}
                                  alt={existingUserDefaultName}
                                />
                              )}
                            </div>
                            <button
                              className="btnRemovePeopleFromSocial"
                              data-bs-toggle="tooltip"
                              title=""
                              data-bs-original-title="Remove"
                              aria-label="Remove"
                            >
                              <i className="bi bi-x-lg" />
                            </button>
                          </div>
                          <div className="col-lg-3 mb-1 mb-lg-0">
                            <select
                              className="form-select"
                              value={selectedRole}
                              onChange={e => {
                                setSelectedRole(e.target.value)
                              }}
                            >
                              <option value={""}>== ROLES ==</option>
                              {settingRole.map((roleData, roleIndex) => (
                                <option key={roleIndex} value={roleData.id}>
                                  {roleData.role}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-lg-4 mb-1 mb-lg-0 text-end">
                            <div className="dropdown socialActionShareWith">
                              <button
                                className="btn dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="bi bi-link-45deg" /> Attached, Not Shared
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-link-45deg" /> Attached, Not Shared
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-2 optionalMsgContainer d-none">
                            <input type="text" className="form-control" placeholder="Optional Message " />
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />

                      <div className="row">
                        <div className="col-lg-12 text-center">
                          <button type="button" className="btn btn-primary" onClick={saveCalendarContact}>
                            <i className="bi bi-check2" /> Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row addNewUserRow">
            <div className="col-lg-12 text-center">
              {showContactButton && (
                <button
                  className="btnAddNewGreenDashed btnAddNewInvoiceUser"
                  id="btnAddNewInvoiceUser"
                  data-bs-toggle="tooltip"
                  onClick={() => {
                    businessRoleTable()
                    setShowContact(true)
                    setShowContactButton(false)
                  }}
                >
                  <OverlayTrigger placement="top" overlay={<Tooltip>Add New Person / Role</Tooltip>}>
                    <i className="bi bi-person-fill" />
                  </OverlayTrigger>
                </button>
              )}

              {showContactButtonGroup && (
                <span>
                  {" "}
                  <button
                    className="btnAddNewGreenDashed btnAddNewGroupAudience"
                    id="btnAddNewGroupAudience"
                    data-bs-toggle="tooltip"
                    onClick={() => {
                      setShowContactGroup(true)
                      setShowContactButtonGroup(false)
                    }}
                  >
                    <OverlayTrigger placement="top" overlay={<Tooltip>Add New Group / Audience</Tooltip>}>
                      <i className="bi bi-people-fill" />
                    </OverlayTrigger>
                  </button>
                </span>
              )}
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
export default PeopleAndPermissionPanel
