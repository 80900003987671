import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import Detail from "../../../components/Calendar/Detail/detail"
import ReduxWrapper from "../../../redux/reduxWrapper"

const CalendarDetail = props => {
  return <PrivateRoute component={Detail} location={props.location} selectedId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<CalendarDetail {...props} />} />
export default WrappedPage
